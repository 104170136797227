<template>
    <div class="main-content">
        <!-- Blog Details Start-->
        <section class="section section-blog">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 order-lg-1 mt-lg-0 mt-4 wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1.5s">
                        <article class="blog-post-left border mb-5">
                            <div class="row">
                                <div class="col-lg-12">
									<div class="post-image">
                                            <img :src="image" class="img-fluid img-thumbnail border-0" loading="lazy">
									</div>
                                    <div class="blog-post-content text-left mt-3">
                                        <h4 class="blog-post-title">
                                            <div class="text-left text-light">
												{{date}}
                                            </div>
                                        </h4>
                                    </div>
                                    <div class="post-content">
 										<div class="post-event d-flex align-items-center my-3">
                                         <div> <h4> <a href=""><strong> {{title}} </strong></a> </h4> </div>
                                        </div>
										<div v-html="text"></div>
									</div>
                                </div>
                            </div>
                        </article>
                    </div>
					<div class="col-lg-4 order-lg-2 wow fadeIn" data-wow-delay="0.1s" data-wow-duration="1.5s">
                        <aside class="sidebar sidebar-padding border">
                            <div class="tags-post">
                                <ul class="blog-news list-unstyled">
                                    <li class="blog-item">
                                        <h6 class="post-news-title">Tags</h6>
                                    </li>
                                </ul>

                                <div class="blog-news-content">
                                    <div class="blog-post-list">
                                        <ul class="list-unstyled list-inline mb-0">
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">Sports </a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">Events </a>
                                                </div>
                                            </li>
                                            <li class="list-inline-item">
                                                <div class="blog-news-info">
                                                    <a href="#" class="text-light">NLSE</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import emailjs from 'emailjs-com'

export default {
  name: 'blogdetails',
  data () {
    return {
      email: '',
      message: '',
      errors: [],
	  id: this.$route.params.id,
	  image: this.$route.params.image,
	  text: this.$route.params.text,
	  title: this.$route.params.title,
	  summary: this.$route.params.summary,
	  date: this.$route.params.date,
      contactFormEmailTo: 'nilanjan.banerjee@eccalon.com',
      contactFormEmailCc: 'nilanjan.banerjee@eccalon.com'
    }
  },
  methods: {
    sendEmail (e) {
      this.errors = []
      if (!this.email) this.errors.push('Email required.')
      if (this.email) {
        try {
          emailjs.sendForm('service_914fhnu', 'template_q0tvcye', e.target,
            'user_C6JHRFE95hQ3x7jJ8aOl1')
        } catch (error) {
          console.log({ error })
        }
        // Reset form field
        this.email = ''
      }
    }
  }
}
</script>
<style scoped>

.blog-post-image {
    display: block;
    overflow: hidden;
    position: relative;
}

.blog-post-image::after {
        content: "";
        width: 0;
        height: 4px;
        bottom: 0;
        position: absolute;
        left: auto;
        right: 0;
        -webkit-transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
        -o-transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
        transition: width .6s cubic-bezier(.25,.8,.25,1) 0s;
        background: #f2b636;
    }

.blog-item:hover .blog-post-image::after {
    width: 100%;
    left: 0;
    right: auto;
}

.blog-post-image img {
    -webkit-transition: all .3s cubic-bezier(.645,.045,.355,1);
    -o-transition: all .3s cubic-bezier(.645,.045,.355,1);
    transition: all .3s cubic-bezier(.645,.045,.355,1);
}
td {
  width: 5% ;
}
.adjust-line-height {
  line-height: 1em;
}
br {
        line-height: 50%;
     }
.blog-news-content .blog-post-info a {
    font-size: 16px;
}

.blog-news-content li:hover .blog-post-info a {
    color: #1C4CA3 !important;
}

.blog-news-content .blog-post-info .post-date {
    font-size: 14px;
    line-height: normal;
    margin-top: 5px;
}

.post-news-title {
    font-size: 18px;
    margin-bottom: 20px;
    padding-bottom: 11px;
    position: relative;
    border-bottom: 1px solid #eee;
}

.post-event a {
    color: #f2b636;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

    .post-event a:hover {
        color: #f2b636;
    }

.post-news-title:before {
    position: absolute;
    width: 50px;
    height: 2px;
    content: "";
    left: 0;
    bottom: -1px;
    opacity: 1;
    background-color: #f2b636;
}

.sidebar-padding {
    padding: 30px;
}

.blog-post-left {
    padding: 10px;
}

.blog-side-padding {
    margin-top: 30px;
}

.sidebar, .blog-post-left, .blog-author-section {
    border-color: #eee !important;
    position: relative;
}

    .blog-post-left::before {
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;
        content: "";
        width: 0;
        height: 3px;
        position: absolute;
        left: 50%;
        bottom: 0;
        opacity: 0;
        background-color: #f2b636;
    }

    .blog-post-left:hover::before {
        width: 100%;
        opacity: 1;
        left: 0;
    }

    .sidebar input.form-control {
        font-size: 15px;
        height: auto;
        border: none;
        border: 2px solid #ededed;
        border-radius: 25px !important;
        background-color: transparent;
        color: #000;
        padding-left: 12px;
        padding-right: 60px;
    }

        .sidebar input.form-control:focus {
            box-shadow: none;
            outline: none;
        }

        .sidebar input.form-control::placeholder {
            color: #989898;
            font-weight: 400;
        }

.btn-search {
    line-height: normal;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 25px 25px 0;
    height: 40px;
    width: 50px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
}

    .btn-search:hover {
        background-color: #f2b636;
        ;
    }

    .btn-search .mdi {
        color: #fff;
        font-size: 24px;
    }

.blog-post-list li {
    margin: 0 0 15px 0;
}

.blog-post-list li {
    border-bottom: none;
    padding-bottom: 0;
}

.blog-news-content .blog-post-info a {
    font-size: 16px;
}

.blog-news-content li:hover .blog-post-info a {
    color: #1C4CA3 !important;
}

.blog-news-content .blog-post-info .post-date {
    font-size: 14px;
    line-height: normal;
    margin-top: 5px;
}

.author-img {
    display: contents;
}

.tags-post li a {
    position: relative;
    display: inline-block;
    padding: 6px 15px;
    font-size: 15px !important;
    font-weight: 500;
    background-color: transparent;
    border: 1px solid #ededed;
    transition: all 0.5s ease-in-out;
}

    .tags-post li a:hover {
        background-color: #f2b636;
        color: #fff !important;
        border: 1px solid #f2b636;
    }

.blockquote {
    border-left: 2px solid #ededed;
    padding: 10px 0 10px 40px;
}

    .blockquote p {
        font-size: 18px;
    }

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

/*==========================
        8.PORTFOLIO
============================*/
.portfolio-box {
    overflow: hidden;
    position: relative;
}

    .portfolio-box:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: -webkit-linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 44.79%,rgba(0,0,0,.35) 63.54%,rgba(0,0,0,.80) 100%);
        background: -o-linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 44.79%,rgba(0,0,0,.35) 63.54%,rgba(0,0,0,.80) 100%);
        background: linear-gradient(180deg,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 44.79%,rgba(0,0,0,.35) 63.54%,rgba(0,0,0,.80) 100%);
        transition: all .4s ease;
    }

    .portfolio-box img {
        transition: all 0.4s;
    }

.filters li a {
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    padding: 5px 20px 5px;
    color: #314584;
    border-radius: 30px;
    font-weight: 400;
    border: 2px solid #314584;
    transition: all 0.3s;
    margin-bottom: 10px;
}

    .filters li a.active, .filters li a:hover {
        background: #f2b636;
        color: #fff;
        border: 2px solid #f2b636;
    }

.img-overlay {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 1rem 2rem;
    transition: all .3s ease;
    z-index: 9;
}

.portfolio-box h5 {
    font-size: 18px;
}

.portfolio-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    visibility: hidden;
    opacity: 0;
    font-size: 24px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index: 2;
}

    .portfolio-icon a {
        color: #fff;
    }

        .portfolio-icon a:hover {
            color: #fff;
        }

    .portfolio-icon:hover {
        background: #f2b636;
    }

.portfolio-box:hover .portfolio-icon {
    visibility: visible;
    opacity: 1;
}

.portfolio-box:hover:after {
    background: linear-gradient(180deg,rgba(0,0,0,.80) 0%,rgba(0,0,0,.80) 44.79%,rgba(0,0,0,.80) 63.54%,rgba(0,0,0,.80) 100%);
}
.isotope, .isotope .isotope-item {
    transition: 0.8s;
}

    .isotope .isotope-item {
        -webkit-transition-property: -webkit-transform, opacity;
        -moz-transition-property: -moz-transform, opacity;
        transition-property: transform, opacity;
    }

.scale-image {
    overflow: hidden;
}
</style>
